// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/home","parentId":"@@/global-layout","id":"1"},"2":{"name":"登录","path":"/login","parentId":"@@/global-layout","id":"2"},"3":{"name":"首页","path":"/home","parentId":"@@/global-layout","id":"3"},"4":{"name":"聊天","path":"/chat","parentId":"@@/global-layout","id":"4"},"5":{"name":"随航","path":"/accompanying","parentId":"@@/global-layout","id":"5"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Login__index" */'@/pages/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Home__index" */'@/pages/Home/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__Chat__index" */'@/pages/Chat/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__Accompanying__index" */'@/pages/Accompanying/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/www/wwwroot/preview/poppaaws-new-web/src/layouts/index.tsx')),
},
  };
}
